import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/views/theme/Base"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/views/pages/Dashboard1.vue"),
        },
        {
          path: "/Profile",
          name: "Profile",
          redirect: "/Profile/Edit:id",
          component: () => import("@/views/pages/vuetify/Vuetify.vue"),
          children:[
            {
              path: "Edit/:id",
              name: "EditProfile",
              component: () => import("@/views/pages/profile/Edit.vue"),
            }
          ]
        },
        {
          path: "/User",
          name: "User",
          redirect: "/User/Search",
          component: () => import("@/views/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "Search",
              name: "SearchUser",
              component: () => import("@/views/pages/user/Search.vue"),
            },
            {
              path: "Add",
              name: "AddUser",
              component: () => import("@/views/pages/user/Add.vue"),
            },
            {
              path: "Edit/:id",
              name: "EditUser",
              component: () => import("@/views/pages/user/Edit.vue"),
            },
            {
              path: "EditRole/:id",
              name: "EditRoleUser",
              component: () => import("@/views/pages/user/EditRole.vue"),
            },
          ],
        },
        {
          path: "/MemberCards",
          name: "MemberCards",
          redirect: "/MemberCards/Search",
          component: () => import("@/views/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "Search",
              name: "SearchMemberCards",
              component: () => import("@/views/pages/member-cards/Search.vue"),
            },
            {
              path: "Generate",
              name: "GenerateMemberCards",
              component: () =>
                import("@/views/pages/member-cards/Generate.vue"),
            },
            {
              path: "Manage",
              name: "ManageMemberCards",
              component: () => import("@/views/pages/member-cards/Manage.vue"),
            },
            {
              path: "Add",
              name: "AddMemberCards",
              component: () => import("@/views/pages/member-cards/Add.vue"),
            },
            {
              path: "Edit/:id",
              name: "EditMemberCards",
              component: () => import("@/views/pages/member-cards/Edit.vue"),
            },
          ],
        },
        {
          path: "/Admin",
          name: "Admin",
          redirect: "/Admin/Search",
          component: () => import("@/views/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "Search",
              name: "SearchAdmin",
              component: () => import("@/views/pages/admin/Search.vue"),
            },
            {
              path: "Add",
              name: "AddAdmin",
              component: () => import("@/views/pages/admin/Add.vue"),
            },
            {
              path: "Edit/:id",
              name: "EditAdmin",
              component: () => import("@/views/pages/admin/Edit.vue"),
            },
          ],
        },
        {
          path: "/Branch",
          name: "Branch",
          redirect: "/Branch/Search",
          component: () => import("@/views/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "Search",
              name: "SearchBranch",
              component: () => import("@/views/pages/branch/Search.vue"),
            },
            {
              path: "Add",
              name: "AddBranch",
              component: () => import("@/views/pages/branch/Add.vue"),
            },
            {
              path: "Edit/:id",
              name: "EditBranch",
              component: () => import("@/views/pages/branch/Edit.vue"),
            },
          ],
        },
        {
          path: "/HomepageBanner",
          name: "HomepageBanner",
          redirect: "/HomepageBanner/Search",
          component: () => import("@/views/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "Search",
              name: "SearchHomepageBanner",
              component: () =>
                import("@/views/pages/homepage-banner/Search.vue"),
            },
            {
              path: "Add",
              name: "AddHomepageBanner",
              component: () => import("@/views/pages/homepage-banner/Add.vue"),
            },
            {
              path: "Edit/:id",
              name: "EditHomepageBanner",
              component: () => import("@/views/pages/homepage-banner/Edit.vue"),
            },
          ],
        },
        {
          path: "/TermandPrivacy",
          name: "TermandPrivacy",
          redirect: "/TermandPrivacy/Search",
          component: () => import("@/views/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "Search",
              name: "SearchTermandPrivacy",
              component: () =>
                import("@/views/pages/termandprivacy/Search.vue"),
            },
            {
              path: "Add",
              name: "AddTermandPrivacy",
              component: () => import("@/views/pages/termandprivacy/Add.vue"),
            },
            {
              path: "Edit/:id",
              name: "EditTermandPrivacy",
              component: () => import("@/views/pages/termandprivacy/Edit.vue"),
            },
          ],
        },
        {
          path: "/ContactUs",
          name: "ContactUs",
          redirect: "/ContactUs/Search",
          component: () => import("@/views/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "Search",
              name: "SearchContactUs",
              component: () =>
                import("@/views/pages/contact-us/Search.vue"),
            },
            {
              path: "Add",
              name: "AddContactUs",
              component: () => import("@/views/pages/contact-us/Add.vue"),
            },
            {
              path: "Edit/:id",
              name: "EditContactUs",
              component: () => import("@/views/pages/contact-us/Edit.vue"),
            },
          ],
        },
        {
          path: "/SplashScreen",
          name: "SplashScreen",
          redirect: "/SplashScreen/Search",
          component: () => import("@/views/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "Search",
              name: "SearchSplashScreen",
              component: () =>
                import("@/views/pages/splashscreen/Search.vue"),
            },
            {
              path: "Add",
              name: "AddSplashScreen",
              component: () => import("@/views/pages/splashscreen/Add.vue"),
            },
            {
              path: "Edit/:id",
              name: "EditSplashScreen",
              component: () => import("@/views/pages/splashscreen/Edit.vue"),
            },
          ],
        },
        {
          path: "/ManageNotification",
          name: "ManageNotification",
          redirect: "/ManageNotification/Search",
          component: () => import("@/views/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "Search",
              name: "SearchManageNotification",
              component: () =>
                import("@/views/pages/managenotification/Search.vue"),
            },
            {
              path: "Add",
              name: "AddManageNotification",
              component: () => import("@/views/pages/managenotification/Add.vue"),
            },
            {
              path: "Edit/:id",
              name: "EditManageNotification",
              component: () => import("@/views/pages/managenotification/Edit.vue"),
            },
          ],
        },
        {
          path: "/Promotion",
          name: "Promotion",
          redirect: "/Promotion/Search",
          component: () => import("@/views/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "Search",
              name: "SearchPromotion",
              component: () => import("@/views/pages/promotion/Search.vue"),
            },
            {
              path: "Add",
              name: "AddPromotion",
              component: () => import("@/views/pages/promotion/Add.vue"),
            },
            {
              path: "Edit/:id",
              name: "EditPromotion",
              component: () => import("@/views/pages/promotion/Edit.vue"),
            },
            {
              path: "UploadExcel/:id",
              name: "UploadExcelPromotion",
              component: () =>
                import("@/views/pages/promotion/UploadExcel.vue"),
            },
            {
              path: "DetailCoupon/:id",
              name: "DetailCoupon",
              component: () =>
                import("@/views/pages/promotion/DetailCoupon.vue"),
            },
          ],
        },
        {
          path: "/PDPA",
          name: "PDPA",
          redirect: "/PDPA/Search",
          component: () => import("@/views/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "Search",
              name: "SearchPDPA",
              component: () => import("@/views/pages/pdpa/Search.vue"),
            }
          ],
        },
        {
          path: "/Menu",
          name: "Menu",
          redirect: "/Menu/Search",
          component: () => import("@/views/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "Search",
              name: "SearchMenu",
              component: () => import("@/views/pages/menu/Search.vue"),
            },
            {
              path: "Add",
              name: "AddMenu",
              component: () => import("@/views/pages/menu/Add.vue"),
            },
            {
              path: "Edit/:id",
              name: "EditMenu",
              component: () => import("@/views/pages/menu/Edit.vue"),
            },
          ],
        },
        {
          path: "/Report",
          name: "Report",
          redirect: "/Report/ReportUseApp",
          component: () => import("@/views/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "ReportUseApp",
              name: "ReportUseApp",
              component: () => import("@/views/pages/report/ReportUseApp.vue"),
            },
            {
              path: "ReportBellPoint",
              name: "ReportBellPoint",
              component: () =>
                import("@/views/pages/report/ReportBellPoint.vue"),
            },
            {
              path: "ReportCustomerDetail",
              name: "ReportCustomerDetail",
              component: () =>
                import("@/views/pages/report/ReportCustomerDetail.vue"),
            },
            {
              path: "ReportMemberCard",
              name: "ReportMemberCard",
              component: () =>
                import("@/views/pages/report/ReportMemberCard.vue"),
            },
            {
              path: "ReportUseMemberCard",
              name: "ReportUseMemberCard",
              component: () =>
                import("@/views/pages/report/ReportUseMemberCard.vue"),
            },
            {
              path: "ReportUseBellPoint",
              name: "ReportUseBellPoint",
              component: () =>
                import("@/views/pages/report/ReportUseBellPoint.vue"),
            },
            {
              path: "ReportRankBellPoint",
              name: "ReportRankBellPoint",
              component: () =>
                import("@/views/pages/report/ReportRankBellPoint.vue"),
            },
            {
              path: "ReportUsePromotionBirthDate",
              name: "ReportUsePromotionBirthDate",
              component: () =>
                import("@/views/pages/report/ReportUsePromotionBirthDate.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/views/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/views/pages/error/Error-1.vue"),
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/views/pages/error/Error-2.vue"),
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/views/pages/error/Error-3.vue"),
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/views/pages/error/Error-4.vue"),
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/views/pages/error/Error-5.vue"),
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/views/pages/error/Error-6.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/views/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/views/pages/auth/Login"),
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/views/pages/auth/Register"),
        },
        {
          name: "ResetPassword",
          path: "/ResetPassword",
          component: () => import("@/views/pages/auth/ResetPassword"),
        },
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/views/pages/error/Error-1.vue"),
    },
  ],
});
