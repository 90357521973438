export const locale = {
    TOPBAR: {
        HI: "สวัสดี",
    },
    TRANSLATOR: {
        SELECT: "เลือกภาษา",
    },
    MENU: {
        DASHBOARD: "แดชบอร์ด",
        PROFILE: {
            EDIT: "แก้ไขโปรไฟล์"
        },
        MASTER: {
            SECTION: "ข้อมูลทั่วไป",
            USER: "ข้อมูลสมาชิก",
            BRANCH: "ข้อมูลสาขา",
            HOMEPAGE_BANNER: "ข้อมูลแบนเนอร์หน้าหลัก",
            TERM_AND_PRIVACY: "ข้อตกลงและนโยบาย",
            CONTACT_US: "ข้อมูลการติดต่อลูกค้าสัมพันธ์",
            SPLASH_SCREEN: "ข้อมูลภาพพื้นหลัง",
            MANAGE_NOTIFICATION: "ข้อมูลการแจ้งเตือน",
            PROMOTION: "ข้อมูลโปรโมชั่น",
            _MENU: "ข้อมูลเมนู",
            ADMIN: "ผู้ดูแลระบบ",
            MANAGE_ADMIN: "การจัดการสิทธิ์ผู้ดูแลระบบ"
        },
        MEMBER_CARD: {
            SECTION: "บัตรสมาชิก",
            GENERATE: "สุ่มเลขบัตรสมาชิก",
            SEARCH: "ค้นหาบัตรสมาชิก",
            MANAGE: "จัดการล็อตบัตรสมาชิก",
            ADD: "สร้างล็อตบัตรสมาชิก",
            EDIT: "แก้ไขล็อตบัตรสมาชิก",
        },
        USER: {
            SECTION: "ข้อมูลสมาชิก",
            SEARCH: "ค้นหาสมาชิก",
            ADD: "เพิ่มสมาชิก",
            EDIT: "แก้ไขสมาชิก",
            EDIT_ROLE: "การจัดการสิทธิ์"
        },
        ADMIN: {
            SECTION: "ข้อมูลผู้ดูแลระบบ",
            SEARCH: "ค้นหาผู้ดูแลระบบ",
            ADD: "เพิ่มสมาชิก",
            EDIT: "แก้ไขสมาชิก",
            EDIT_ROLE: "การจัดการสิทธิ์"
        },
        BRANCH: {
            SECTION: "ข้อมูลสาขา",
            SEARCH: "ค้นหาสาขา",
            ADD: "เพิ่มสาขา",
            EDIT: "แก้ไขสาขา",
        },
        HOMEPAGE_BANNER: {
            SECTION: "ข้อมูลแบนเนอร์หน้าหลัก",
            SEARCH: "ค้นหาแบนเนอร์หน้าหลัก",
            ADD: "เพิ่มแบนเนอร์หน้าหลัก",
            EDIT: "แก้ไขแบนเนอร์หน้าหลัก",
        },
        TERM_AND_PRIVACY: {
            SECTION: "ข้อตกลงและนโยบาย",
            SEARCH: "ค้นหาข้อตกลงและนโยบาย",
            ADD: "เพิ่มข้อตกลงและนโยบาย",
            EDIT: "แก้ไขข้อตกลงและนโยบาย",
        },
        CONTACT_US: {
            SECTION: "ข้อมูลการติดต่อลูกค้าสัมพันธ์",
            SEARCH: "ค้นหาข้อมูลการติดต่อลูกค้าสัมพันธ์",
            ADD: "เพิ่มข้อมูลการติดต่อลูกค้าสัมพันธ์",
            EDIT: "แก้ไขข้อมูลการติดต่อลูกค้าสัมพันธ์",
        },
        SPLASH_SCREEN: {
            SECTION: "ข้อมูลภาพพื้นหลัง",
            SEARCH: "ค้นหาข้อมูลภาพพื้นหลัง",
            ADD: "เพิ่มข้อมูลภาพพื้นหลัง",
            EDIT: "แก้ไขข้อมูลภาพพื้นหลัง",
        },
        MANAGE_NOTIFICATION: {
            SECTION: "ข้อมูลการแจ้งเตือน",
            SEARCH: "ค้นหาข้อมูลการแจ้งเตือน",
            ADD: "เพิ่มข้อมูลการแจ้งเตือน",
            EDIT: "แก้ไขข้อมูลการแจ้งเตือน",
        },
        PROMOTION: {
            SECTION: "ข้อมูลโปรโมชั่น",
            SEARCH: "ค้นหาโปรโมชั่น",
            ADD: "เพิ่มโปรโมชั่น",
            EDIT: "แก้ไขโปรโมชั่น",
            UPLOADEXCEL: "เพิ่มไฟล์โปรโมชั่น",
            DETAILCOUPON : "รายละเอียดคูปอง"
        },
        _MENU: {
            SECTION: "ข้อมูลเมนู",
            SEARCH: "ค้นหาเมนู",
            ADD: "เพิ่มเมนู",
            EDIT: "แก้ไขเมนู",
        },
        REPORT: {
            SECTION: "รายงาน",
            USE_APP: "ยอดการใช้แอปพลิเคชัน",
            USE_APP_SEARCH: "ค้นหายอดการใช้แอปพลิเคชัน",
            BELL_POINT: "การได้รับ Bell Point",
            BELL_POINT_SEARCH: "ค้นหาการได้รับ Bell Point",
            CUSTOMER_DETAIL: "รายละเอียดลูกค้า",
            CUSTOMER_DETAIL_SEARCH: "ค้นหารายละเอียดลูกค้า",
            MEMBER_CARD : "บัตรสมาชิก",
            MEMBER_CARD_SEARCH : "ค้นหาบัตรสมาชิก",
            USE_MEMBER_CARD:"การใช้บัตรสมาชิก",
            USE_MEMBER_CARD_SEARCH:"ค้นหาการใช้บัตรสมาชิก",
            USE_BELL_POINT:"การแลก Bell Point",
            USE_BELL_POINT_SEARCH:"ค้นหาการแลก Bell Point",
            RANK_BELL_POINT:"การจัดอันดับ Bell point",
            RANK_BELL_POINT_SEARCH:"ค้นหาการจัดอันดับ Bell point",
            USE_PROMOTION_BIRTH_DATE:"การใช้ส่วนลดวันเกิด",
            USE_PROMOTION_BIRTH_DATE_SEARCH:"ค้นหาการใช้ส่วนลดวันเกิด",
        },
        PDPA: {
            SECTION: "PDPA",
            SEARCH: "ค้นหา PDPA",
            PDPA_LIST: "ข้อมูล PDPA",
        }
    },
    SHARED: {
        PLEASE_WAIT: "รอสักครู่",
        PROCESSING: "ระบบกำลังประมวลผล...",
        RESET_BUTTON: "รีเซ็ต",
        CONFIRM_BUTTON: "ยืนยัน",
        RECHECK_BUTTON: "ตรวจสอบอีกครั้ง",
        CHOOSE_BUTTON: "เลือก",
        CANCEL_BUTTON: "ยกเลิก",
        DOWNLOAD_BUTTON: "ดาวน์โหลด",
        CLOSE_BUTTON: "ปิด",
        SEARCH_BUTTON: "ค้นหา",
        EXPORT_BUTTON:"Export",
        SAVE_BUTTON: "บันทึก",
        KEYWORD: "คำค้นหา",
        START_TYPING_TO_SEARCH: "พิมพ์เพื่อเริ่มการค้นหา",
        REGISTERED: "ลงทะเบียนแล้ว",
        NOT_REGISTER: "ยังไม่ลงทะเบียน",
        EXPIRED: "หมดอายุแล้ว",
        NOT_EXPIRED: "ยังไม่หมดอายุ",
        SEARCH_RESULT: "ผลการค้นหา",
        TOGGLE_DIALOG_HEADER: "คำสั่งแก้ไขสถานะ",
        TOGGLE_CLOSE_DIALOG_TEXT: "กรุณายืนยันคำสั่งแก้ไขสถานะ หลังจากกดแล้วจะส่งผลให้สถานะถูกปิดใช้งาน",
        TOGGLE_OPEN_DIALOG_TEXT: "กรุณายืนยันคำสั่งแก้ไขสถานะ หลังจากกดแล้วจะส่งผลให้สถานะถูกเปิดใช้งาน",
        TOGGLE_RESULT_DIALOG_HEADER: "ผลการแก้ไขสถานะ",
        DELETE_RESULT_DIALOG_HEADER: "ผลการลบข้อมูล",
        DELETE_DIALOG_HEADER: "คำสั่งการลบข้อมูล",
        CONFIRM_DELETE_DIALOG_TEXT: "กรุณายืนยันคำสั่งการลบข้อมูล หลังจากกดแล้วจะส่งผลให้ไม่สามารถใช้งานข้อมูลดังกล่าวได้",
        PUSH_NOTI_DIALOG_HEADER: "คำสั่งส่งการแจ้งเตือน",
        PUSH_NOTI_DIALOG_TEXT: "กรุณายืนยันคำสั่งส่งการแจ้งเตือน หลังจากกดแล้วจะส่งการแจ้งเตือนไปยังแอพพลิเคชันตามเวลาที่ระบุ",
        PUSH_NOTI_CANCEL_DIALOG_TEXT: "กรุณายืนยันคำสั่งส่งการแจ้งเตือน หลังจากกดแล้วจะยกเลิกส่งการแจ้งเตือนไปยังแอพพลิเคชันตามเวลาที่ระบุ",
        PUSH_NOTI_RESULT_DIALOG_HEADER: "ผลการส่งการแจ้งเตือน",
        PUSH_NOTI_DATE: "วันที่ส่งการแจ้งเตือน",
        PUSH_NOTI_TIME: "เวลาส่งการแจ้งเตือน",
    },
    AUTH: {
        LOGIN: {
            TITLE: "ข้อมูลผู้ใช้งาน",
            BUTTON: "เข้าสู่ระบบ",
            USERNAME_OR_EMAIL: "ชื่อผู้ใช้งานหรืออีเมล",
            USERNAME_OR_EMAIL_REQUIRED: "กรุณากรอกผู้ใช้งานหรืออีเมล",
            PASSWORD: "รหัสผ่าน",
            PASSWORD_REQUIRED: "กรุณากรอกรหัสผ่าน",
            FORGOT_PASSWORD: "ลืมรหัสผ่าน?",
        },
        UNAUTHENTICATED_MODAL: {
            TITLE: "SESSION TIMEOUT!",
            TEXT: "ข้อมูลผู้ใช้งานของท่านหมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง",
            LOGIN_BUTTON: "เข้าสู่ระบบอีกครั้ง",
        },
        FORGOT_PASSWORD: {
            TITLE: "ลืมรหัสผ่าน",
            PASSWORD: "รหัสผ่าน",
            CONFIRM_PASSWORD: "ยืนยันรหัสผ่าน",
            CONFIRM_BUTTON: "ยืนยัน",
            CANCEL_BUTTON: "ยกเลิก",
            PASSWORD_REQUIRED: "กรุณากรอกรหัสผ่าน",
            CONFIRM_PASSWORD_REQUIRED: "กรุณากรอกยืนยันรหัสผ่าน",
            COMFIRM_PASSWORD_EQ: "ยืนยันรหัสผ่านต้องเหมือนกับรหัสผ่าน",
            PASSWORD_COUNTER: "รหัสผ่านต้องมีขนาดน้อยกว่าหรือเท่ากับ 20 ตัวอักษร",
            COMFIRM_PASSWORD_COUNTER: "ยืนยันรหัสผ่านต้องมีขนาดน้อยกว่าหรือเท่ากับ 20 ตัวอักษร",
            PASSWORD_COUNTER_LT: "รหัสผ่านต้องมีขนาดมากกว่าหรือเท่ากับ 6 ตัวอักษร",
            COMFIRM_PASSWORD_COUNTER_LT: "ยืนยันรหัสผ่านต้องมีขนาดมากกว่าหรือเท่ากับ 6 ตัวอักษร",
            SUCCESS_DIALOG_HEADER: "สำเร็จ",
            SUCCESS_DIALOG_TEXT: "แก้ไขข้อมูลสำเร็จ",
        },
    },
    SYS_VARIABLE: {
        MEMBER_CLASS_NORMAL: "สมาชิกทั่วไป",
        MEMBER_CLASS_GOLD: "สมาชิกบัตรทอง",
        OPEN_ID_YES: "ใช้งาน",
        OPEN_ID_NO: "ไม่ใช้งาน",
        IN_ACTIVE_STATUS_YES: "เปิดใช้งาน",
        IN_ACTIVE_STATUS_NO: "ปิดใช้งาน",
        IS_TRAVEL_CITY_YES: "เป็นเมืองท่องเที่ยว",
        IS_TRAVEL_CITY_NO: "ไม่เป็นเมืองท่องเที่ยว",
        IS_SHOW_YES: "แสดง",
        IS_SHOW_NO: "ไม่แสดง",
        PROMOTION: "โปรโมชั่น",
        MENU: "เมนู",
        EXTERNAL: "ลิงค์ภายนอก",
        PUSH_NOTI: "ส่ง",
        CANCEL_PUSH_NOTI: "ยกเลิก",
    },
    MEMBER_CARD: {
        GENERATE: {
            LOT: "ล็อตบัตรสมาชิก",
            MEMBER_CLASS: "ประเภทบัตรสมาชิก",
            MEMBER_CLASS_REQUIRED: "กรุณากรอกประเภทบัตรสมาชิก",
            QUANTITY: "จำนวนบัตรสมาชิก",
            QUANTITY_REQUIRED: "กรุณากรอกจำนวนบัตรสมาชิก",
            QUANTITY_GT_0: "จำนวนบัตรสมาชิกจะต้องมีค่ามากกว่า 0",
            // EXPIRE_MONTH: "เดือน/ปี ที่บัตรหมดอายุ",
            // EXPIRE_MONTH_REQUIRED: "กรุณาเลือกเดือน/ปี ที่บัตรหมดอายุ",
            // GENERATE_REF_CODE: "รหัสอ้างอิง (ใช้อ้างอิงล็อตการสร้างบัตรสมาชิก)",
            // GENERATE_REF_CODE_REQUIRED: "กรุณากรอกรหัสอ้างอิง",
            // GENERATE_REF_CODE_MIN_10_CHAR: "รหัสอ้างอิงต้องมีความยาวอย่างน้อย 10 ตัวอักษร",
            GENERATE_BUTTON: "สุ่มเลขบัตรสมาชิก",
            EXAMPLE: "ตัวอย่างเลขบัตรสมาชิก",
            CONFIRM_DIALOG_HEADER: "ยืนยันคำสั่งสุ่มบัตรสมาชิก",
            CONFIRM_DIALOG_TEXT: "คุณต้องการสุ่มบัตรสมาชิกใช่หรือไม่?",
            DOWNLOAD_DIALOG_HEADER: "ดาวน์โหลด",
            DOWNLOAD_DIALOG_TEXT: "คลิกลิ้งค์เพื่อดาวน์โหลดไฟล์ Excel",
            GENERATE_REF_CODE: "รหัสอ้างอิงล็อตบัตรสมาชิก",
            GENERATE_REF_CODE_REQUIRED: "กรุณาเลือกล็อตบัตรสมาชิก",
            CARD_PICTURE_FRONT: "รูปภาพหน้าบัตรสมาชิก",
            // CARD_PICTURE_FRONT_REQUIRED: "กรุณาเลือกรูปภาพหน้าบัตรสมาชิก",
            // CARD_PICTURE_FRONT_INVALID_SIZE: "รูปภาพหน้าบัตรสมาชิกจะต้องมีขนาด 1024 x 768 pixels เท่านั้น",
            // CARD_PICTURE_FRONT_HINT: "รูปภาพหน้าบัตรสมาชิกขนาด 1024 x 768 pixels",
            CARD_PICTURE_BACK: "รูปภาพหลังบัตรสมาชิก",
            // CARD_PICTURE_BACK_REQUIRED: "กรุณาเลือกรูปภาพหลังบัตรสมาชิก",
            // CARD_PICTURE_BACK_INVALID_SIZE: "รูปภาพหลังบัตรสมาชิกจะต้องมีขนาด 1024 x 768 pixels เท่านั้น",
            // CARD_PICTURE_BACK_HINT: "รูปภาพหลังบัตรสมาชิกขนาด 1024 x 768 pixels",
        },
        SEARCH: {
            MEMBER_CLASS: "ประเภทสมาชิก",
            CARD_NUMBER: "เลขบัตรสมาชิก",
            SECURE_CODE: "รหัสตรวจสอบ",
            EXPIRE_DATE: "วันที่บัตรหมดอายุ",
            IS_REGISTERED: "สถานะ",
            IS_EXPIRED: "บัตรหมดอายุ?",
            REGISTERED_DATE: "วันที่ลงทะเบียน",
            GENERATE_REF_CODE: "รหัสอ้างอิง",
            BATCH_CODE: "Batch",
            KEYWORD_HINT: "ค้นหาจาก เลขบัตรสมาชิก รหัสอ้างอิง",
            EXPIRE_MONTH: "เดือน/ปี ที่บัตรหมดอายุ",
            EXPIRE_MONTH_REQUIRED: "กรุณาเลือกเดือน/ปี ที่บัตรหมดอายุ",
            REGISTERED_DATE_REQUIRED: "กรุณาเลือกวัน/เดือน/ปี ที่ลงทะเบียน",
            ACTION: "การจัดการ",
            
        },
        MANAGE: {
            EXPIRE_MONTH: "เดือน/ปี ที่บัตรหมดอายุ",
            EXPIRE_MONTH_REQUIRED: "กรุณาเลือกเดือน/ปี ที่บัตรหมดอายุ",
            GENERATE_REF_CODE: "รหัสอ้างอิง (ใช้อ้างอิงล็อตการสร้างบัตรสมาชิก)",
            GENERATE_REF_CODE_REQUIRED: "กรุณากรอกรหัสอ้างอิง",
            GENERATE_REF_CODE_MIN_10_CHAR: "รหัสอ้างอิงต้องมีความยาวอย่างน้อย 10 ตัวอักษร",
            CARD_PICTURE_FRONT: "รูปภาพหน้าบัตรสมาชิก",
            CARD_PICTURE_FRONT_REQUIRED: "กรุณาเลือกรูปภาพหน้าบัตรสมาชิก",
            CARD_PICTURE_FRONT_INVALID_SIZE: "รูปภาพหน้าบัตรสมาชิกจะต้องมีขนาด 1024 x 768 pixels เท่านั้น",
            CARD_PICTURE_FRONT_HINT: "รูปภาพหน้าบัตรสมาชิกขนาด 1024 x 768 pixels",
            CARD_PICTURE_BACK: "รูปภาพหลังบัตรสมาชิก",
            CARD_PICTURE_BACK_REQUIRED: "กรุณาเลือกรูปภาพหลังบัตรสมาชิก",
            CARD_PICTURE_BACK_INVALID_SIZE: "รูปภาพหลังบัตรสมาชิกจะต้องมีขนาด 1024 x 768 pixels เท่านั้น",
            CARD_PICTURE_BACK_HINT: "รูปภาพหลังบัตรสมาชิกขนาด 1024 x 768 pixels",
            GENERATE_BUTTON_LOT: "เพิ่มล็อตบัตรสมาชิก",
            CONFIRM_DIALOG_TEXT: "คุณต้องการสร้างล็อตบัตรสมาชิกใช่หรือไม่?",
            CONFIRM_DIALOG_HEADER: "ยืนยันคำสั่งสร้างล็อตบัตรสมาชิก",
            KEYWORD_HINT: "ค้นหาจากรหัสอ้างอิงหรือชื่อล็อต",
            IS_SHOW_YES: "แสดง",
            IS_SHOW_NO: "ไม่แสดง",
            NAME: "รหัสอ้างอิง",
            REGISTERED_DATE: "วันที่ลงทะเบียน",
            SUCCESS_DIALOG_HEADER: "สำเร็จ",
            ADD_SUCCESS_DIALOG_TEXT: "สร้างล็อตบัตรสมาชิกสำเร็จ"

        },
        // ADD_EDIT: {
        //     NAME: "ชื่อภาพพื้นหลัง",
        //     PICTURE: "รูปภาพพื้นหลัง",
        //     START_DATE: "วันที่เริ่มการแสดงผล",
        //     END_DATE: "วันที่สิ้นสุดการแสดงผล",
        //     START_TIME: "เวลาเริ่มการแสดงผล",
        //     END_TIME: "เวลาสิ้นสุดการแสดงผล",
        //     IN_ACTIVE_STATUS: "เปิดใช้งาน",

        //     NAME_VALIDATION: "กรุณากรอกชื่อภาพพื้นหลัง",
        //     NAME_COUNTER: "ชื่อภาพพื้นหลังต้องมีขนาดน้อยกว่าหรือเท่ากับ 200 ตัวอักษร",

        //     PICTURE_VALIDATION: "กรุณาเลือกรูปภาพ",
        //     PICTURE_HINT: "รองรับนามสกุล .jpg, .jpeg, .png และรูปภาพแนวนอนเท่านั้น (ขนาดที่แนะนำคือ 1600px x 900px)",

        //     START_DATE_VALIDATION: "กรุณากรอกวันที่เริ่มการแสดงผล",
        //     END_DATE_VALIDATION: "กรุณากรอกวันที่สิ้นสุดการแสดงผล",
        //     START_TIME_VALIDATION: "กรุณากรอกเวลาเริ่มการแสดงผล",
        //     END_TIME_VALIDATION: "กรุณากรอกเวลาสิ้นสุดการแสดงผล",

        //     SUCCESS_DIALOG_HEADER: "สำเร็จ",
        //     ADD_SUCCESS_DIALOG_TEXT: "สร้างข้อมูลภาพพื้นหลังสำเร็จ",
        //     EDIT_SUCCESS_DIALOG_TEXT: "แก้ไขข้อมูลภาพพื้นหลังสำเร็จ",
        // },
    },
    REPORT: {
        USE_APP: {
            START_DATE: "วันที่",
            END_DATE: "ถึง",
            CARD_NUMBER: "รหัสบัตร",
            MEMBER_CLASS: "Level Member",
            FIRST_NAME: "ชื่อ",
            LAST_NAME: "นามสกุล",
            AGE: "อายุ",
            GENDER: "เพศ",
            CREATE_DATE: "วันที่เข้า",
            SYSTEM: "ระบบที่ใช้งาน",
            KEYWORD_HINT: "ค้นหาจาก รหัสบัตร ชื่อ นามสกุล",
            GENDER_M : "ชาย",
            GENDER_F : "หญิง"
        },
        BELL_POINT: {
            START_DATE: "วันที่",
            END_DATE: "ถึง",
            CARD_NUMBER: "รหัสบัตร",
            MEMBER_CLASS: "Level Member",
            FIRST_NAME: "ชื่อ",
            LAST_NAME: "นามสกุล",
            GET_POINT: "พอยท์ที่ได้รับ",
            TOTAL_POINT: "พอยท์สะสม",
            VALID_POINT: "พอยท์ที่ใช้ได้",
            USE_POINT: "ใช้พอยท์",
            POINT_DISCOUNT: "รายการส่วนลด",
            POINT_MENU: "รายการคูปอง",
            POINT_REMAIN: "พอยท์คงเหลือ",
            KEYWORD_HINT: "ค้นหาจาก รหัสบัตร",
            FREE_POINT : "ฟรีพอยท์",

            OLD_MEMBER_CARD : "เลขบัตรเก่า",
            TRANSFER_POINT : "พอยท์โอนมา",
            EXPIRE_DATE : "วันหมดอายุ (พ้อยท์โอนมา)",
            SALE_NUM : "เลขที่บิล",
            PROMOTION_ID:"ID โปรโมชั่น",
            PROMOTION_NAME:"ชื่อโปรโมชั่น",
            TOTAL : "จำนวนเงิน",

        },
        CUSTOMER_DETAIL: {
            CARD_NUMBER: "รหัสบัตร",
            CARD_AUTHEN: "Authen",
            EMAIL:"อีเมล",
            FIRST_NAME: "ชื่อ",
            LAST_NAME: "นามสกุล",
            GENDER: "เพศ",
            BIRTH_DATE: "วันเกิด",
            AGE: "อายุ",
            PHONE_NUMBER: "เบอร์โทร",
            ADDRESS: "ที่อยู่",
            BRANCH_CODE: "รหัสสาขา",
            SALE_BRANCH: "ซื้อที่สาขา",
            SUM_POINT: "Point(สะสม)",
            BALANCE_POINT: "Point(ใช้ได้)",
            MEMBER_CLASS_CODE: "Level Member",
            IN_ACTIVE_STATUS: "สถานะ",
            UP_LEVEL_DATE: "วันที่ปรับสถานะ",
            SALE_DATE: "วันที่ซื้อบัตร",
            REGISTERED_DATE: "วันที่ลงทะเบียน",
            EXPIRE_DATE: "วันหมดอายุบัตร",
            KEYWORD_HINT: "ค้นหาจาก รหัสบัตร ชื่อ นามสกุล ที่อยู่ ซื้อที่สาขา Email",
        },
        MEMBER_CARD :{
            START_DATE: "วันที่ขาย ตั้งแต่",
            END_DATE: "ถึง",
            CARD_NUMBER: "รหัสบัตร",
            SECURECODE : "รหัสความปลอดภัย",
            GENERATE_REF_CODE : "รหัสอ้างอิงล็อต",
            MEMBER_CLASS_CODE: "Level Member",
            OLD_CARD : "เลขบัตรอ้างอิง",
            BRANCH_CODE : "รหัสสาขา",
            SALE_BRANCH : "สาขาที่ขาย",
            BILL_NUMBER : "เลขที่บิล",
            SALE_DATE : "วันที่ขาย",
            EXPIRE_DATE : "วันหมดอายุ",
            KEYWORD_HINT: "ค้นหาจาก รหัสบัตร Level Member"
        },
        USE_MEMBER_CARD :{
            START_DATE: "วันที่ ตั้งแต่",
            END_DATE: "ถึง",
            CARD_NUMBER: "รหัสบัตร",
            MEMBER_CLASS_CODE: "Level Member",
            FIRST_NAME: "ชื่อ",
            LAST_NAME: "นามสกุล",
            BRANCH_NAMETH: "สาขาที่ขาย",
            SALE_NUM : "เลขที่บิล",
            TOTAL : "จำนวนเงิน",
            EARNPOINT : "พอยท์ที่ได้รับ",
            COUPON_NAME :"ชื่อคูปอง",
            REDEEM_POINT : "พอยท์แลกส่วนลด",
            SALE_DATE : "วันที่ใช้",
            KEYWORD_HINT: "ค้นหาจาก รหัสบัตร ชื่อ นามสกุล รหัสสาขา เลขที่บิล",
            BRANCH_CODE:"รหัสสาขา",
            MEMBER_DISCOUNT:"ส่วนลดบัตรสมาชิก",
            SUM_POINT:"พ้อยท์ที่สะสม",
            USE_POINT:"พ้อยท์ที่ใช้ไป",
            PROMOTION_ID:"ID โปรโมชั่น",
            PROMOTION_NAME:"ชื่อโปรโมชั่น",
            BALANCE_POINT:"พ้อยท์คงเหลือ",
            START_MEMBER_CARD:"รหัสบัตร ตั้งแต่",
            END_MEMBER_CARD:"ถึง",
        },
        USE_BELL_POINT :{
            START_DATE: "วันที่ ตั้งแต่",
            END_DATE: "ถึง",
            SALE_DATE : "วันที่ใช้",
            CARD_NUMBER: "รหัสบัตร",
            MEMBER_CLASS_CODE: "Level Member",
            FIRST_NAME: "ชื่อ",
            LAST_NAME: "นามสกุล",
            BRANCH_CODE:"รหัสสาขา",
            BRANCH_NAMETH: "สาขาที่ขาย",
            SALE_NUM : "เลขที่บิล",
            TRANS_POINT: "Bell Point ที่ได้รับ",
            VALID_POINT: "Bell Point ที่ใช้ได้",
            SUM_POINT: "Bell Point สะสม",
            REDEEM_POINT: "Bell point แลกคูปอง",
            BALANCE_POINT: "Bell Point คงเหลือ",
            COUPON_NAME: "Bell point แลกคูปองเมนู",
            KEYWORD_HINT: "ค้นหาจาก รหัสบัตร ชื่อ นามสกุล รหัสสาขา เลขที่บิล ชื่อคูปอง",
        },
        RANK_BELL_POINT :{
            KEYWORD_HINT: "ค้นหาจาก รหัสบัตร ชื่อ นามสกุล",
            MEMBER_CLASS_CODE: "Level Member",
            START_MEMBER_CARD:"รหัสบัตร ตั้งแต่",
            END_MEMBER_CARD:"ถึง",

            TYPE_POINT:"ประเภทพ้อยท์",
            START_POINT:"พ้อยท์ ตั้งแต่",
            START_POINT_HINT: "ค้นหาพ้อยท์ ตั้งแต่",
            END_POINT:"ถึง",
            END_POINT_HINT: "ค้นหาพ้อยท์ ถึง",
            RANK_POINT:"ลำดับ(Rank)",
            RANK_POINT_HINT:"ค้นจาก ลำดับ(Rank)",

            CARD_NUMBER: "รหัสบัตร",
            FIRST_NAME: "ชื่อ",
            LAST_NAME: "นามสกุล",
            BRANCH_CODE:"รหัสสาขา",
            BRANCH_NAMETH: "ซื้อที่สาขา",
            SUM_POINT: "Point(สะสม)",
            BALANCE_POINT: "Point(ใช้ได้)",
            REGISTERED_DATE: "วันที่ลงทะเบียน",
            EXPIRE_DATE: "วันที่หมดอายุ",
        },
        USE_PROMOTION_BIRTH_DATE: {
            START_USE_DATE: "วันที่ใช้สิทธิ์ ตั้งแต่",
            END_USE_DATE: "ถึง",

            START_BIRTH_DATE: "เดือนเกิด ตั้งแต่",
            END_BIRTH_DATE: "ถึง",

            CARD_NUMBER: "รหัสบัตร",
            FIRST_NAME: "ชื่อ",
            LAST_NAME: "นามสกุล",
            BIRTH_DATE: "วันเกิด",
            PHONE_NUMBER: "เบอร์โทร",
            ADDRESS: "ที่อยู่",
            BRANCH_CODE: "รหัสสาขา",
            BRANCH_NAME: "ซื้อที่สาขา",
            SALE_NUM: "เลขบิล",
            TOTAL: "จำนวนเงิน",
            DISCOUNT: "ส่วนลด",
            IS_USE_PROMOTION_DATE: "สถานะ",
            USE_PROMOTION_DATE: "วันที่ใช้สิทธิ์",
            KEYWORD_HINT: "ค้นหาจาก รหัสบัตร ชื่อ นามสกุล",
        },
    },
    PDPA : {
        SEARCH: {
            USER_NAME: "ชื่อผู้ใช้งาน",
            EMAIL: "อีเมล",
            FULL_NAME: "ชื่อ - นามสกุล",
            PHONE_NUMBER: "โทรศัพท์",
            IN_ACTIVE_STATUS: "สถานะผู้ใช้งาน",
            TITLE: "ชื่อข้อตกลงและนโยบาย",
            TYPE: "ประเภทข้อตกลงและนโยบาย",
            APPROVE: "สถานะการยืนยัน",
            ACTION: "การจัดการ",
            KEYWORD_HINT: "ค้นหาจาก",
            TERM_AND_PRIVACY: "ค้นหาข้อตกลงและนโยบาย",
            TYPE_TERM_AND_PRIVACY: "ค้นหาประเภทข้อตกลงและนโยบาย",
            TERMANDPRIVACY_TYPE_PRIVACY: "นโยบายความเป็นส่วนตัว",
            TERMANDPRIVACY_TYPE_TERM: "ข้อกำหนดและเงื่อนไข",
        }
    },
    USER: {
        SEARCH: {
            USERNAME: "ชื่อผู้ใช้งาน",
            EMAIL: "อีเมล",
            ROLE: "บทบาท",
            DISPLAY_NAME: "ชื่อ - ชื่อสกุล",
            PHONE_NUMBER: "โทรศัพท์",
            POSITION: "ตำแหน่ง",
            IS_OPEN_ID: "ใช้งาน OpenId?",
            MEMBER_CARD: "รหัสบัตร",
            LOGIN_PROVIDER: "Authen",

            LAST_LOG_ON_DATE_TIME: "วันที่เข้าใช้งานล่าสุด",
            ACTION: "การจัดการ",
            KEYWORD_HINT: "ค้นหาจาก ชื่อผู้ใช้งาน อีเมล ชื่อ-ชื่อสกุล โทรศัพท์ ตำแหน่ง",
        },
        ADD_EDIT: {
            EMAIL: "อีเมล",
            ROLE: "บทบาท",
            FIRST_NAME: "ชื่อ",
            LAST_NAME: "นามสกุล",
            PHONE_NUMBER: "โทรศัพท์",
            POSITION: "ตำแหน่ง",
            PASSWORD: "รหัสผ่าน",
            OLDPASSWORD: "รหัสผ่านเก่า",
            NEWPASSWORD: "รหัสผ่านใหม่",
            COMFIRM_PASSWORD: "ยืนยันรหัสผ่าน",
            IN_ACTIVE_STATUS: "เปิดใช้งาน",
            EMAIL_VALIDATION: "กรุณากรอกอีเมล",
            FIRST_NAME_VALIDATION: "กรุณากรอกชื่อ",
            LAST_NAME_VALIDATION: "กรุณากรอกนามสกุล",
            PASSWORD_VALIDATION: "กรุณากรอกรหัสผ่าน",
            COMFIRM_PASSWORD_VALIDATION: "กรุณากรอกยืนยันรหัสผ่าน",
            EMAIL_COUNTER: "อีเมลต้องมีขนาดน้อยกว่าหรือเท่ากับ 256 ตัวอักษร",
            FIRST_NAME_COUNTER: "ชื่อต้องมีขนาดน้อยกว่าหรือเท่ากับ 450 ตัวอักษร",
            LAST_NAME_COUNTER: "นามสกุลต้องมีขนาดน้อยกว่าหรือเท่ากับ 450 ตัวอักษร",
            PASSWORD_COUNTER: "รหัสผ่านต้องมีขนาดน้อยกว่าหรือเท่ากับ 20 ตัวอักษร",
            COMFIRM_PASSWORD_COUNTER: "ยืนยันรหัสผ่านต้องมีขนาดน้อยกว่าหรือเท่ากับ 20 ตัวอักษร",
            PHONE_NUMBER_COUNTER: "โทรศัพท์ต้องมีขนาดน้อยกว่าหรือเท่ากับ 50 ตัวอักษร",
            POSITION_COUNTER: "ตำแหน่งต้องมีขนาดน้อยกว่าหรือเท่ากับ 100 ตัวอักษร",
            EMAIL_FORMAT: "รูปแบบอีเมลไม่ถูกต้อง",
            COMFIRM_PASSWORD_EQ: "ยืนยันรหัสผ่านต้องเหมือนกับรหัสผ่าน",
            PASSWORD_COUNTER_LT: "รหัสผ่านต้องมีขนาดมากกว่าหรือเท่ากับ 6 ตัวอักษร",
            COMFIRM_PASSWORD_COUNTER_LT: "ยืนยันรหัสผ่านต้องมีขนาดมากกว่าหรือเท่ากับ 6 ตัวอักษร",
            SUCCESS_DIALOG_HEADER: "สำเร็จ",
            ADD_SUCCESS_DIALOG_TEXT: "สร้างข้อมูลสมาชิกสำเร็จ",
            EDIT_SUCCESS_DIALOG_TEXT: "แก้ไขข้อมูลสมาชิกสำเร็จ",
            BIRTH_DATE: "วันเกิด",
            MEMBER_CARD: "รหัสบัตรสมาชิก",
            USERNAME_OR_EMAIL: "ชื่อผู้ใช้งานหรืออีเมล",
            USERNAME_OR_EMAIL_VALIDATION: "กรุณากรอกชื่อผู้ใช้งานหรืออีเมล",

        },
    },
    BRANCH: {
        SEARCH: {
            BRANCH_CODE: "รหัสสาขา",
            NAME_EN: "ชื่อภาษาอังกฤษ",
            NAME_TH: "ชื่อภาษาไทย",
            FLOOR: "ชั้น",
            PHONE_NUMBER: "โทรศัพท์",
            IS_TRAVEL_CITY: "เป็นเมืองท่องเที่ยว?",
            LATITUDE: "ละติจูด",
            LONGITUDE: "ลองจิจูด",
            IN_ACTIVE_STATUS: "สถานะ",
            ACTION: "การจัดการ",
            KEYWORD_HINT: "ค้นหาจาก รหัสสาขา ชื่อภาษาอังกฤษ ชื่อภาษาไทย ชั้น โทรศัพท์",
        },
        ADD_EDIT: {
            BRANCH_CODE: "รหัสสาขา",
            NAME_EN: "ชื่อภาษาอังกฤษ",
            NAME_TH: "ชื่อภาษาไทย",
            FLOOR: "ชั้น",
            PHONE_NUMBER: "โทรศัพท์",
            IS_TRAVEL_CITY: "เป็นเมืองท่องเที่ยว",
            LATITUDE: "ละติจูด",
            LONGITUDE: "ลองจิจูด",
            IN_ACTIVE_STATUS: "เปิดใช้งาน",
            BRANCH_CODE_VALIDATION: "กรุณากรอกรหัสสาขา",
            NAME_EN_VALIDATION: "กรุณากรอกชื่อภาษาอังกฤษ",
            NAME_TH_VALIDATION: "กรุณากรอกชื่อภาษาไทย",
            FLOOR_VALIDATION: "กรุณากรอกชั้น",
            BRANCH_CODE_COUNTER: "รหัสสาขาต้องมีขนาดน้อยกว่าหรือเท่ากับ 50 ตัวอักษร",
            NAME_EN_COUNTER: "ชื่อภาษาอังกฤษต้องมีขนาดน้อยกว่าหรือเท่ากับ 200 ตัวอักษร",
            NAME_TH_COUNTER: "ชื่อภาษาไทยต้องมีขนาดน้อยกว่าหรือเท่ากับ 200 ตัวอักษร",
            FLOOR_COUNTER: "ชั้นต้องมีขนาดน้อยกว่าหรือเท่ากับ 100 ตัวอักษร",
            PHONE_NUMBER_COUNTER: "โทรศัพท์ต้องมีขนาดน้อยกว่าหรือเท่ากับ 50 ตัวอักษร",
            LATITUDE_COUNTER: "ละติจูดต้องมีขนาดน้อยกว่าหรือเท่ากับ 50 ตัวอักษร",
            LONGITUDE_COUNTER: "ลองจิจูดต้องมีขนาดน้อยกว่าหรือเท่ากับ 50 ตัวอักษร",
            SUCCESS_DIALOG_HEADER: "สำเร็จ",
            ADD_SUCCESS_DIALOG_TEXT: "สร้างข้อมูลสาขาสำเร็จ",
            EDIT_SUCCESS_DIALOG_TEXT: "แก้ไขข้อมูลสาขาสำเร็จ",
            PICTURE: "รูปภาพสาขา",
            PICTURE_HINT:"รองรับนามสกุล .jpg, .jpeg, .png และรูปภาพแนวนอนเท่านั้น (ขนาดที่แนะนำคือ 1600px x 900px)",
            PICTURE_VALIDATION: "กรุณาเลือกรูปภาพ",
        },
    },
    HOMEPAGE_BANNER: {
        SEARCH: {
            NAME: "ชื่อแบนเนอร์",
            DESCRIPTION: "รายละเอียด(ภาษาไทย)",
            DESCRIPTION_EN : "รายละอียด(ภาษาอังกฤษ)",
            ORDER: "ลำดับ",
            START_DATE: "วันที่เริ่มการแสดงผล",
            END_DATE: "วันที่สิ้นสุดการแสดงผล",
            IS_SHOW: "แสดง",
            IN_ACTIVE_STATUS: "สถานะ",
            ACTION: "การจัดการ",
            KEYWORD_HINT: "ค้นหาจาก ชื่อแบนเนอร์ รายละเอียด",
            ORDER_RESULT_DIALOG_HEADER: "ผลการแก้ไขลำดับ",
        },
        ADD_EDIT: {
            NAME: "ชื่อแบนเนอร์",
            DESCRIPTION: "รายละเอียด(ภาษาไทย)",
            DESCRIPTION_EN : "รายละอียด(ภาษาอังกฤษ)",
            ORDER: "ลำดับ",
            START_DATE: "วันที่เริ่มการแสดงผล",
            END_DATE: "วันที่สิ้นสุดการแสดงผล",
            START_TIME: "เวลาเริ่มการแสดงผล",
            END_TIME: "เวลาสิ้นสุดการแสดงผล",
            IN_ACTIVE_STATUS: "เปิดใช้งาน",
            PICTURE: "รูปภาพ(ภาษาไทย)",
            PICTURE_EN : "รูปภาพ(ภาษาอังกฤษ)",
            TYPE_LINK: "ประเภทการเชื่อมโยง",
            URL_LINK: "เชื่อมโยงกับ",
            NAME_VALIDATION: "กรุณากรอกชื่อแบนเนอร์",
            ORDER_VALIDATION: "กรุณากรอกลำดับ",
            START_DATE_VALIDATION: "กรุณากรอกวันที่เริ่มการแสดงผล",
            END_DATE_VALIDATION: "กรุณากรอกวันที่สิ้นสุดการแสดงผล",
            START_TIME_VALIDATION: "กรุณากรอกเวลาเริ่มการแสดงผล",
            END_TIME_VALIDATION: "กรุณากรอกเวลาสิ้นสุดการแสดงผล",
            PICTURE_VALIDATION: "กรุณาเลือกรูปภาพ",
            NAME_COUNTER: "ชื่อแบนเนอร์ต้องมีขนาดน้อยกว่าหรือเท่ากับ 50 ตัวอักษร",
            DESCRIPTION_COUNTER: "รายละเอียดต้องมีขนาดน้อยกว่าหรือเท่ากับ 200 ตัวอักษร",
            EXTERNAL_LINK_COUNTER: "เชื่อมโยงกับต้องมีขนาดน้อยกว่าหรือเท่ากับ 450 ตัวอักษร",
            ORDER_GT_0: "ลำดับจะต้องมีค่ามากกว่า 0",
            SUCCESS_DIALOG_HEADER: "สำเร็จ",
            ADD_SUCCESS_DIALOG_TEXT: "สร้างข้อมูลแบนเนอร์หน้าหลักสำเร็จ",
            EDIT_SUCCESS_DIALOG_TEXT: "แก้ไขข้อมูลแบนเนอร์หน้าหลักสำเร็จ",
            PICTURE_HINT: "รองรับนามสกุล .jpg, .jpeg, .png และรูปภาพแนวนอนเท่านั้น (ขนาดที่แนะนำคือ 1600px x 900px)",
        },
    },
    TERM_AND_PRIVACY: {
        SEARCH: {
            TITLE: "ชื่อข้อตกลงและนโยบาย",
            HEADER_TH: "หัวข้อ(ภาษาไทย)",
            HEADER_EN : "หัวข้อ(ภาษาอังกฤษ)",
            DESCRIPTION_TH: "รายละเอียด(ภาษาไทย)",
            DESCRIPTION_EN : "รายละอียด(ภาษาอังกฤษ)",
            VERSION: "เวอร์ชั่นข้อตกลงและนโยบาย",
            IS_SHOW: "แสดง",
            REQUIRE_APPROVE: "ประเภทการยืนยันเท่านั้น",
            IN_ACTIVE_STATUS: "สถานะ",
            ACTION: "การจัดการ",
            KEYWORD_HINT: "ค้นหาจาก ชื่อข้อตกลงและนโยบาย เวอร์ชั่นข้อตกลงและนโยบาย",
        },
        ADD_EDIT: {
            TITLE: "ชื่อข้อตกลงและนโยบาย",
            HEADER_TH: "หัวข้อ(ภาษาไทย)",
            HEADER_EN : "หัวข้อ(ภาษาอังกฤษ)",
            DESCRIPTION_TH: "รายละเอียด(ภาษาไทย)",
            DESCRIPTION_EN : "รายละอียด(ภาษาอังกฤษ)",
            VERSION: "เวอร์ชั่นข้อตกลงและนโยบาย",
            TYPE: "ประเภทข้อตกลงหรือนโยบาย",
            REQUIRE_APPROVE: "ประเภทการยืนยัน",
            IN_ACTIVE_STATUS: "เปิดใช้งาน",

            HEADER_TH_VALIDATION: "กรุณากรอกหัวข้อ(ภาษาไทย)",
            HEADER_EN_VALIDATION: "กรุณากรอกหัวข้อ(ภาษาอังกฤษ)",
            HEADER_TH_COUNTER: "หัวข้อต้องมีขนาดน้อยกว่าหรือเท่ากับ 200 ตัวอักษร",
            HEADER_EN_COUNTER: "หัวข้อต้องมีขนาดน้อยกว่าหรือเท่ากับ 200 ตัวอักษร",


            DESCRIPTION_TH_VALIDATION: "กรุณากรอกรายละเอียด(ภาษาไทย)",
            DESCRIPTION_EN_VALIDATION: "กรุณากรอกรายละเอียด(ภาษาอังกฤษ)",

            VERSION_VALIDATION: "กรุณากรอกเวอร์ชั่นข้อตกลงและนโยบาย",
            TITLE_VALIDATION: "กรุณากรอกชื่อข้อตกลงและนโยบาย",

            TYPE_VALIDATION: "กรุณาเลือกประเภทข้อตกลงหรือนโยบาย",
            REQUIRE_APPROVE_VALIDATION: "กรุณาเลือกประเภทการยืนยัน",

            TITLE_COUNTER: "ชื่อข้อตกลงและนโยบายต้องมีขนาดน้อยกว่าหรือเท่ากับ 200 ตัวอักษร",
            VERSION_COUNTER: "เวอร์ชั่นข้อตกลงและนโยบายน้อยกว่าหรือเท่ากับ 100 ตัวอักษร",

            // DESCRIPTION_TH_COUNTER: "รายละเอียดต้องมีขนาดน้อยกว่าหรือเท่ากับ 50 ตัวอักษร",
            // DESCRIPTION_EN_COUNTER: "เชื่อมโยงกับต้องมีขนาดน้อยกว่าหรือเท่ากับ 50 ตัวอักษร",

            SUCCESS_DIALOG_HEADER: "สำเร็จ",
            ADD_SUCCESS_DIALOG_TEXT: "สร้างชื่อข้อตกลงและนโยบายสำเร็จ",
            EDIT_SUCCESS_DIALOG_TEXT: "แก้ไขชื่อข้อตกลงและนโยบายสำเร็จ",
        },
    },
    CONTACT_US: {
        SEARCH: {
            TITLE: "ชื่อข้อมูลการติดต่อลูกค้าสัมพันธ์",
            // HEADER_TH: "หัวข้อ(ภาษาไทย)",
            // HEADER_EN : "หัวข้อ(ภาษาอังกฤษ)",
            DESCRIPTION_TH: "รายละเอียด(ภาษาไทย)",
            DESCRIPTION_EN : "รายละอียด(ภาษาอังกฤษ)",
            // VERSION: "เวอร์ชั่นข้อตกลงและนโยบาย",
            IS_SHOW: "แสดง",
            // REQUIRE_APPROVE: "ประเภทการยืนยันเท่านั้น",
            IN_ACTIVE_STATUS: "สถานะ",
            ACTION: "การจัดการ",
            KEYWORD_HINT: "ค้นหาจาก ชื่อข้อมูลการติดต่อลูกค้าสัมพันธ์",
        },
        ADD_EDIT: {
            TITLE: "ข้อมูลการติดต่อลูกค้าสัมพันธ์",
            // HEADER_TH: "หัวข้อ(ภาษาไทย)",
            // HEADER_EN : "หัวข้อ(ภาษาอังกฤษ)",
            DESCRIPTION_TH: "รายละเอียด(ภาษาไทย)",
            DESCRIPTION_EN : "รายละอียด(ภาษาอังกฤษ)",
            // VERSION: "เวอร์ชั่นข้อตกลงและนโยบาย",
            // TYPE: "ประเภทข้อตกลงหรือนโยบาย",
            // REQUIRE_APPROVE: "ประเภทการยืนยัน",
            IN_ACTIVE_STATUS: "เปิดใช้งาน",

            // HEADER_TH_VALIDATION: "กรุณากรอกหัวข้อ(ภาษาไทย)",
            // HEADER_EN_VALIDATION: "กรุณากรอกหัวข้อ(ภาษาอังกฤษ)",
            // HEADER_TH_COUNTER: "หัวข้อต้องมีขนาดน้อยกว่าหรือเท่ากับ 200 ตัวอักษร",
            // HEADER_EN_COUNTER: "หัวข้อต้องมีขนาดน้อยกว่าหรือเท่ากับ 200 ตัวอักษร",


            DESCRIPTION_TH_VALIDATION: "กรุณากรอกรายละเอียด(ภาษาไทย)",
            DESCRIPTION_EN_VALIDATION: "กรุณากรอกรายละเอียด(ภาษาอังกฤษ)",
            // VERSION_VALIDATION: "กรุณากรอกเวอร์ชั่นข้อตกลงและนโยบาย",
            TITLE_VALIDATION: "กรุณากรอกข้อมูลการติดต่อลูกค้าสัมพันธ์",


            TITLE_COUNTER: "ข้อมูลการติดต่อลูกค้าสัมพันธ์ต้องมีขนาดน้อยกว่าหรือเท่ากับ 200 ตัวอักษร",
            // VERSION_COUNTER: "เวอร์ชั่นข้อตกลงและนโยบายน้อยกว่าหรือเท่ากับ 100 ตัวอักษร",
            // DESCRIPTION_TH_COUNTER: "รายละเอียดต้องมีขนาดน้อยกว่าหรือเท่ากับ 50 ตัวอักษร",
            // DESCRIPTION_EN_COUNTER: "เชื่อมโยงกับต้องมีขนาดน้อยกว่าหรือเท่ากับ 50 ตัวอักษร",

            SUCCESS_DIALOG_HEADER: "สำเร็จ",
            ADD_SUCCESS_DIALOG_TEXT: "สร้างข้อมูลการติดต่อลูกค้าสัมพันธ์สำเร็จ",
            EDIT_SUCCESS_DIALOG_TEXT: "แก้ไขข้อมูลการติดต่อลูกค้าสัมพันธ์สำเร็จ",
        },
    },
    SPLASH_SCREEN: {
        SEARCH: {
            NAME: "ชื่อภาพพื้นหลัง",
            START_DATE: "วันที่เริ่มการแสดงผล",
            END_DATE: "วันที่สิ้นสุดการแสดงผล",
            IS_SHOW: "แสดง",
            IN_ACTIVE_STATUS: "สถานะ",
            ACTION: "การจัดการ",
            KEYWORD_HINT: "ค้นหาจาก ชื่อภาพพื้นหลัง",
        },
        ADD_EDIT: {
            NAME: "ชื่อภาพพื้นหลัง",
            PICTURE: "รูปภาพพื้นหลัง",
            START_DATE: "วันที่เริ่มการแสดงผล",
            END_DATE: "วันที่สิ้นสุดการแสดงผล",
            START_TIME: "เวลาเริ่มการแสดงผล",
            END_TIME: "เวลาสิ้นสุดการแสดงผล",
            IN_ACTIVE_STATUS: "เปิดใช้งาน",

            NAME_VALIDATION: "กรุณากรอกชื่อภาพพื้นหลัง",
            NAME_COUNTER: "ชื่อภาพพื้นหลังต้องมีขนาดน้อยกว่าหรือเท่ากับ 200 ตัวอักษร",

            PICTURE_VALIDATION: "กรุณาเลือกรูปภาพ",
            PICTURE_HINT: "รองรับนามสกุล .jpg, .jpeg, .png และรูปภาพแนวตั้งเท่านั้น (ขนาดที่แนะนำไม่เกินคือ 1080px x 1920px)",

            START_DATE_VALIDATION: "กรุณากรอกวันที่เริ่มการแสดงผล",
            END_DATE_VALIDATION: "กรุณากรอกวันที่สิ้นสุดการแสดงผล",
            START_TIME_VALIDATION: "กรุณากรอกเวลาเริ่มการแสดงผล",
            END_TIME_VALIDATION: "กรุณากรอกเวลาสิ้นสุดการแสดงผล",

            SUCCESS_DIALOG_HEADER: "สำเร็จ",
            ADD_SUCCESS_DIALOG_TEXT: "สร้างข้อมูลภาพพื้นหลังสำเร็จ",
            EDIT_SUCCESS_DIALOG_TEXT: "แก้ไขข้อมูลภาพพื้นหลังสำเร็จ",
        },
    },
    MANAGE_NOTIFICATION: {
        SEARCH: {
            TITLE: "หัวข้อ",
            BODY: "รายละเอียด",
            USE_DATE: "วันที่แจ้งเตือน",
            SEND_STATUS_CODE: "สถานะการส่งแจ้งเตือน",
            SEND_STATUS_GROUP: "กลุ่มการแจ้งเตือน",
            IN_ACTIVE_STATUS: "สถานะ",
            ACTION: "การจัดการ",
            KEYWORD_HINT: "ค้นหาจาก ชื่อหัวข้อ",
        },
        ADD_EDIT: {
            TITLE: "หัวข้อ(ภาษาไทย)",
            TITLE_EN: "หัวข้อ(ภาษาอังกฤษ)",
            BODY: "รายละเอียด(ภาษาไทย)",
            BODY_EN: "รายละเอียด(ภาษาอังกฤษ)",
            URL: "ลิงค์ภายนอก",
            USE_DATE: "วันที่แจ้งเตือน",
            USE_TIME: "เวลาแจ้งเตือน",
            SEND_STATUS_CODE: "สถานะการส่งแจ้งเตือน",
            SEND_STATUS_GROUP: "กลุ่มการแจ้งเตือน",
            IN_ACTIVE_STATUS: "เปิดใช้งาน",

            TITLE_VALIDATION: "กรุณากรอกหัวข้อ(ภาษาไทย)",
            TITLE_COUNTER: "หัวข้อ(ภาษาไทย)ต้องมีขนาดน้อยกว่าหรือเท่ากับ 200 ตัวอักษร",

            TITLE_EN_VALIDATION: "กรุณากรอกหัวข้อ(ภาษาอังกฤษ)",
            TITLE_EN_COUNTER: "หัวข้อ(ภาษาอังกฤษ)ต้องมีขนาดน้อยกว่าหรือเท่ากับ 200 ตัวอักษร",

            BODY_VALIDATION: "กรุณากรอกรายละเอียด(ภาษาไทย)",
            BODY_COUNTER: "รายละเอียด(ภาษาไทย)ต้องมีขนาดน้อยกว่าหรือเท่ากับ 2000 ตัวอักษร",

            BODY_EN_VALIDATION: "กรุณากรอกรายละเอียด(ภาษาอังกฤษ)",
            BODY_EN_COUNTER: "รายละเอียด(ภาษาอังกฤษ)ต้องมีขนาดน้อยกว่าหรือเท่ากับ 2000 ตัวอักษร",

            URL_COUNTER: "ลิงค์ภายนอกต้องมีขนาดน้อยกว่าหรือเท่ากับ 1000 ตัวอักษร",

            USE_DATE_VALIDATION: "กรุณากรอกวันที่เริ่มการแสดงผล",
            USE_TIME_VALIDATION: "กรุณากรอกเวลาเริ่มการแสดงผล",
            SEND_STATUS_GROUP_VALIDATION: "กรุณาเลือกสถานะการส่งแจ้งเตือน",

            SUCCESS_DIALOG_HEADER: "สำเร็จ",
            ADD_SUCCESS_DIALOG_TEXT: "สร้างข้อมูลการแจ้งเตือนสำเร็จ",
            EDIT_SUCCESS_DIALOG_TEXT: "แก้ไขข้อมูลการแจ้งเตือนสำเร็จ",
        },
    },
    PROMOTION: {
        SEARCH: {
            NAME: "ชื่อโปรโมชั่น",
            DESCRIPTION: "รายละเอียด",
            ORDER: "ลำดับ",
            START_DATE: "วันที่เริ่มการแสดงผล",
            END_DATE: "วันที่สิ้นสุดการแสดงผล",
            IS_SHOW: "แสดง",
            IN_ACTIVE_STATUS: "สถานะ",
            ACTION: "การจัดการ",
            KEYWORD_HINT: "ค้นหาจาก ชื่อโปรโมชั่น รายละเอียด",
            ORDER_RESULT_DIALOG_HEADER: "ผลการแก้ไขลำดับ",
            IS_PUSH_NOTI: "การแจ้งเตือน",
        },
        ADD_EDIT: {
            NAME: "ชื่อโปรโมชั่น",
            DESCRIPTION: "รายละเอียด",
            DESCRIPTION_TH: "รายละอียด(ภาษาไทย)",
            DESCRIPTION_EN: "รายละเอียด(ภาษาอังกฤษ)",
            ORDER: "ลำดับ",
            START_DATE: "วันที่เริ่มการแสดงผล",
            END_DATE: "วันที่สิ้นสุดการแสดงผล",
            START_TIME: "เวลาเริ่มการแสดงผล",
            END_TIME: "เวลาสิ้นสุดการแสดงผล",
            IN_ACTIVE_STATUS: "เปิดใช้งาน",
            PICTURE: "รูปภาพ",
            PICTURE_TH: "รูปภาพ(ภาษาไทย)",
            PICTURE_EN: "รูปภาพ(ภาษาอังกฤษ)",
            NAME_VALIDATION: "กรุณากรอกชื่อโปรโมชั่น",
            ORDER_VALIDATION: "กรุณากรอกลำดับ",
            START_DATE_VALIDATION: "กรุณากรอกวันที่เริ่มการแสดงผล",
            END_DATE_VALIDATION: "กรุณากรอกวันที่สิ้นสุดการแสดงผล",
            START_TIME_VALIDATION: "กรุณากรอกเวลาเริ่มการแสดงผล",
            END_TIME_VALIDATION: "กรุณากรอกเวลาสิ้นสุดการแสดงผล",
            PICTURE_VALIDATION: "กรุณาเลือกรูปภาพ",
            NAME_COUNTER: "ชื่อโปรโมชั่นต้องมีขนาดน้อยกว่าหรือเท่ากับ 50 ตัวอักษร",
            DESCRIPTION_COUNTER: "รายละเอียดต้องมีขนาดน้อยกว่าหรือเท่ากับ 200 ตัวอักษร",
            ORDER_GT_0: "ลำดับจะต้องมีค่ามากกว่า 0",
            SUCCESS_DIALOG_HEADER: "สำเร็จ",
            ADD_SUCCESS_DIALOG_TEXT: "สร้างข้อมูลโปรโมชั่นสำเร็จ",
            EDIT_SUCCESS_DIALOG_TEXT: "แก้ไขข้อมูลโปรโมชั่นสำเร็จ",
            PICTURE_HINT: "รองรับนามสกุล .jpg, .jpeg, .png (ขนาดที่แนะนำคือ 16:9)",
            SHOW_ON_PRIVILEGE_PAGE: "แสดงในหน้าจอสิทธิพิเศษ",
            POINT_TO_REDEEM: "จำนวน Bell Point",
            POINT_TO_REDEEM_VALIDATION: "กรุณากรอกจำนวน Bell Point ที่ต้องใช้ในการขอโปรโมชั่นโค้ด",
        },
        UPLOAD_EXCEL: {
            NAME: "ชื่อโปรโมชั่น",
            FILE: "ไฟล์โปรโมชั่นโค้ด",
            FILE_HINT: "รองรับนามสกุล .csv เท่านั้น",
            FILE_VALIDATION: "กรุณาเลือกไฟล์โปรโมชั่่นโค้ด",
            TABLE_TITLE: "รายละเอียดไฟล์",
            SUCCESS_DIALOG_HEADER: "สำเร็จ",
            SUCCESS_DIALOG_TEXT: "เพิ่มไฟล์โปรโมชั่นสำเร็จ",
        },
        DETAILCOUPON : {
            NAME: "ชื่อโปรโมชั่น",
            TABLE_TITLE: "รายละเอียดคูปอง",
            KEYWORD_HINT: "ค้นหาจาก Coupon Number",
            REDEEN_COUPON : "แลกคูปอง",
            IS_USED: "ใช้คูปอง",
            REDEEM_IS_YES: "คูปองที่แลกแล้ว",
            REDEEM_IS_NO: "คูปองที่ยังไม่ได้แลก",
            IS_USED_YES: "คูปองที่ถูกใช้แล้ว",
            IS_USED_NO: "คูปองที่ยังไม่ได้ใช้",
            ACTION: "การจัดการ",
            DELETE_ALL : "ลบคูปองที่ยังไม่ได้ใช้งานทั้งหมด"

        },
    },
    _MENU: {
        SEARCH: {
            NAME: "ชื่อเมนู",
            DESCRIPTION: "รายละเอียด",
            ORDER: "ลำดับ",
            START_DATE: "วันที่เริ่มการแสดงผล",
            END_DATE: "วันที่สิ้นสุดการแสดงผล",
            IS_SHOW: "แสดง",
            IN_ACTIVE_STATUS: "สถานะ",
            ACTION: "การจัดการ",
            KEYWORD_HINT: "ค้นหาจาก ชื่อเมนู รายละเอียด",
            ORDER_RESULT_DIALOG_HEADER: "ผลการแก้ไขลำดับ",
        },
        ADD_EDIT: {
            NAME: "ชื่อเมนู",
            DESCRIPTION: "รายละเอียด",
            DESCRIPTION_TH: "รายละเอียด(ภาษาไทย)",
            DESCRIPTION_EN: "รายละเอียด(ภาษาอังกฤษ)",
            ORDER: "ลำดับ",
            START_DATE: "วันที่เริ่มการแสดงผล",
            END_DATE: "วันที่สิ้นสุดการแสดงผล",
            START_TIME: "เวลาเริ่มการแสดงผล",
            END_TIME: "เวลาสิ้นสุดการแสดงผล",
            IN_ACTIVE_STATUS: "เปิดใช้งาน",
            PICTURE: "รูปภาพ",
            PICTURE_TH: "รูปภาพ(ภาษาไทย)",
            PICTURE_EN: "รูปภาพ(ภาษาอังกฤษ)",
            NAME_VALIDATION: "กรุณากรอกชื่อเมนู",
            ORDER_VALIDATION: "กรุณากรอกลำดับ",
            START_DATE_VALIDATION: "กรุณากรอกวันที่เริ่มการแสดงผล",
            END_DATE_VALIDATION: "กรุณากรอกวันที่สิ้นสุดการแสดงผล",
            START_TIME_VALIDATION: "กรุณากรอกเวลาเริ่มการแสดงผล",
            END_TIME_VALIDATION: "กรุณากรอกเวลาสิ้นสุดการแสดงผล",
            PICTURE_VALIDATION: "กรุณาเลือกรูปภาพ",
            NAME_COUNTER: "ชื่อเมนูต้องมีขนาดน้อยกว่าหรือเท่ากับ 50 ตัวอักษร",
            DESCRIPTION_COUNTER: "รายละเอียดต้องมีขนาดน้อยกว่าหรือเท่ากับ 200 ตัวอักษร",
            ORDER_GT_0: "ลำดับจะต้องมีค่ามากกว่า 0",
            SUCCESS_DIALOG_HEADER: "สำเร็จ",
            ADD_SUCCESS_DIALOG_TEXT: "สร้างข้อมูลเมนูสำเร็จ",
            EDIT_SUCCESS_DIALOG_TEXT: "แก้ไขข้อมูลเมนูสำเร็จ",
            PICTURE_HINT: "รองรับนามสกุล .jpg, .jpeg, .png (ขนาดที่แนะนำคือ 16:9)",
        },
    },
};